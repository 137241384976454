export function useCompanyInfo() {
  const { isIos } = useDevice();
  const { t } = useI18n();
  const { public: { contacts, socials, apps } } = useRuntimeConfig();
  const appLink = computed(() => (isIos ? t('appStoreLink') : apps.playMarket));

  return {
    contacts,
    socials,
    apps,
    appLink,
  };
}
